// customize some Bootstrap variables
$primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

@import '~@fortawesome/fontawesome-free/css/all.css';

.login-container {
    font-size: 18px;
    
    .form-control {
        height: 36px;
    }

    .form-group {
      position: relative;
  
      .password-showhide {
        font-size: 30px;
        position: absolute;
        bottom: -7px;
        right: 10px;
        cursor: pointer;
      }
  
      .hide-password {
        display: none;
      }
    }
  }